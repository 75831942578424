<template>	
	<v-row>
		<v-col cols="12">
			<v-data-table
				:headers="headers"
				:items="items"
				fixed-header
				class="elevation-0"
				:search="search"
				id="tabla_teachers"
			>

				<template v-slot:item.nombre_completo="{ item }" class="cyan lighten-4 pa-1">
					{{ item.nombre_completo }}
				</template>

				<template v-slot:item.avance_teoria="{ item }" class="cyan lighten-4 pa-1">
					<v-chip v-if="item.avance_teoria == 0" small color="red" dark> {{ item.avance_teoria }}</v-chip>
					<v-chip v-if="item.avance_teoria > 0 && item.avance_teoria < 100 " small color="orange" dark> {{ item.avance_teoria }}</v-chip>
					<v-chip v-if="item.avance_teoria >= 100 " small color="green" dark>{{ item.avance_teoria }}</v-chip>
				</template>

				<template v-slot:item.avance_practica="{ item }" class="cyan lighten-4 pa-1">
					<v-chip v-if="item.avance_practica == 0" small color="red" dark> {{ item.avance_practica }}</v-chip>
					<v-chip v-if="item.avance_practica > 0 && item.avance_practica < 100 " small color="orange" dark> {{ item.avance_practica }}</v-chip>
					<v-chip v-if="item.avance_practica >= 100 " small color="green" dark>{{ item.avance_practica }}</v-chip>
				</template>

				<template v-slot:item.avance_general="{ item }" class="cyan lighten-4 pa-1">
					<v-chip v-if="item.avance_general == 0" small color="red" dark> {{ item.avance_general }}</v-chip>
					<v-chip v-if="item.avance_general > 0 && item.avance_general < 100 " small color="orange" dark> {{ item.avance_general }}</v-chip>
					<v-chip v-if="item.avance_general >= 100 " small color="green" dark>{{ item.avance_general }}</v-chip>
				</template>

				<template v-slot:item.avance_inbi_t="{ item }" class="cyan lighten-4 pa-1">
					<v-chip v-if="item.avance_inbi_t == 0" small color="red" dark> {{ item.avance_inbi_t }}</v-chip>
					<v-chip v-if="item.avance_inbi_t > 0 && item.avance_inbi_t < 100 " small color="orange" dark> {{ item.avance_inbi_t }}</v-chip>
					<v-chip v-if="item.avance_inbi_t >= 100 " small color="green" dark>{{ item.avance_inbi_t }}</v-chip>
				</template>

				<template v-slot:item.avance_inbi_p="{ item }" class="cyan lighten-4 pa-1">
					<v-chip v-if="item.avance_inbi_p == 0" small color="red" dark> {{ item.avance_inbi_p }}</v-chip>
					<v-chip v-if="item.avance_inbi_p > 0 && item.avance_inbi_p < 100 " small color="orange" dark> {{ item.avance_inbi_p }}</v-chip>
					<v-chip v-if="item.avance_inbi_p >= 100 " small color="green" dark>{{ item.avance_inbi_p }}</v-chip>
				</template>
				
				<template v-slot:item.avance_inbi_g="{ item }" class="cyan lighten-4 pa-1">
					<v-chip v-if="item.avance_inbi_g == 0" small color="red" dark> {{ item.avance_inbi_g }}</v-chip>
					<v-chip v-if="item.avance_inbi_g > 0 && item.avance_inbi_g < 100 " small color="orange" dark> {{ item.avance_inbi_g }}</v-chip>
					<v-chip v-if="item.avance_inbi_g >= 100 " small color="green" dark>{{ item.avance_inbi_g }}</v-chip>
				</template>

				<template v-slot:item.avance_teens_t="{ item }" class="cyan lighten-4 pa-1">
					<v-chip v-if="item.avance_teens_t == 0" small color="red" dark> {{ item.avance_teens_t }}</v-chip>
					<v-chip v-if="item.avance_teens_t > 0 && item.avance_teens_t < 100 " small color="orange" dark> {{ item.avance_teens_t }}</v-chip>
					<v-chip v-if="item.avance_teens_t >= 100 " small color="green" dark>{{ item.avance_teens_t }}</v-chip>
				</template>

				<template v-slot:item.avance_teens_p="{ item }" class="cyan lighten-4 pa-1">
					<v-chip v-if="item.avance_teens_p == 0" small color="red" dark> {{ item.avance_teens_p }}</v-chip>
					<v-chip v-if="item.avance_teens_p > 0 && item.avance_teens_p < 100 " small color="orange" dark> {{ item.avance_teens_p }}</v-chip>
					<v-chip v-if="item.avance_teens_p >= 100 " small color="green" dark>{{ item.avance_teens_p }}</v-chip>
				</template>
				
				<template v-slot:item.avance_teens_g="{ item }" class="cyan lighten-4 pa-1">
					<v-chip v-if="item.avance_teens_g == 0" small color="red" dark> {{ item.avance_teens_g }}</v-chip>
					<v-chip v-if="item.avance_teens_g > 0 && item.avance_teens_g < 100 " small color="orange" dark> {{ item.avance_teens_g }}</v-chip>
					<v-chip v-if="item.avance_teens_g >= 100 " small color="green" dark>{{ item.avance_teens_g }}</v-chip>
				</template>

				<template v-slot:item.nivel_1_fast="{ item }" class="cyan lighten-4 pa-1">
					<v-chip v-if="item.nivel_1_fast == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_1_fast == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_1_fast == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_1_fast == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_2_fast="{ item }">
					<v-chip v-if="item.nivel_2_fast == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_2_fast == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_2_fast == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_2_fast == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_3_fast="{ item }">
					<v-chip v-if="item.nivel_3_fast == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_3_fast == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_3_fast == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_3_fast == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_4_fast="{ item }">
					<v-chip v-if="item.nivel_4_fast == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_4_fast == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_4_fast == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_4_fast == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_5_fast="{ item }">
					<v-chip v-if="item.nivel_5_fast == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_5_fast == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_5_fast == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_5_fast == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_6_fast="{ item }">
					<v-chip v-if="item.nivel_6_fast == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_6_fast == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_6_fast == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_6_fast == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_7_fast="{ item }">
					<v-chip v-if="item.nivel_7_fast == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_7_fast == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_7_fast == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_7_fast == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_8_fast="{ item }">
					<v-chip v-if="item.nivel_8_fast == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_8_fast == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_8_fast == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_8_fast == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_9_fast="{ item }">
					<v-chip v-if="item.nivel_9_fast == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_9_fast == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_9_fast == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_9_fast == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_10_fast="{ item }">
					<v-chip v-if="item.nivel_10_fast == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_10_fast == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_10_fast == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_10_fast == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_11_fast="{ item }">
					<v-chip v-if="item.nivel_11_fast == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_11_fast == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_11_fast == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_11_fast == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_12_fast="{ item }">
					<v-chip v-if="item.nivel_12_fast == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_12_fast == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_12_fast == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_12_fast == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_13_fast="{ item }">
					<v-chip v-if="item.nivel_13_fast == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_13_fast == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_13_fast == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_13_fast == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_14_fast="{ item }">
					<v-chip v-if="item.nivel_14_fast == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_14_fast == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_14_fast == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_14_fast == 3" small color="green" dark></v-chip>
				</template>

				<!-- cscacacacac -->
				<!-- cscacacacac -->
				<!-- cscacacacac -->

				<template v-slot:item.nivel_1_inbi="{ item }" class="cyan lighten-4 pa-1">
					<v-chip v-if="item.nivel_1_inbi == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_1_inbi == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_1_inbi == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_1_inbi == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_2_inbi="{ item }">
					<v-chip v-if="item.nivel_2_inbi == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_2_inbi == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_2_inbi == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_2_inbi == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_3_inbi="{ item }">
					<v-chip v-if="item.nivel_3_inbi == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_3_inbi == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_3_inbi == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_3_inbi == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_4_inbi="{ item }">
					<v-chip v-if="item.nivel_4_inbi == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_4_inbi == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_4_inbi == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_4_inbi == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_5_inbi="{ item }">
					<v-chip v-if="item.nivel_5_inbi == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_5_inbi == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_5_inbi == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_5_inbi == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_6_inbi="{ item }">
					<v-chip v-if="item.nivel_6_inbi == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_6_inbi == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_6_inbi == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_6_inbi == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_7_inbi="{ item }">
					<v-chip v-if="item.nivel_7_inbi == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_7_inbi == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_7_inbi == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_7_inbi == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_8_inbi="{ item }">
					<v-chip v-if="item.nivel_8_inbi == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_8_inbi == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_8_inbi == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_8_inbi == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_9_inbi="{ item }">
					<v-chip v-if="item.nivel_9_inbi == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_9_inbi == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_9_inbi == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_9_inbi == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_10_inbi="{ item }">
					<v-chip v-if="item.nivel_10_inbi == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_10_inbi == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_10_inbi == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_10_inbi == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_11_inbi="{ item }">
					<v-chip v-if="item.nivel_11_inbi == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_11_inbi == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_11_inbi == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_11_inbi == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_12_inbi="{ item }">
					<v-chip v-if="item.nivel_12_inbi == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_12_inbi == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_12_inbi == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_12_inbi == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_13_inbi="{ item }">
					<v-chip v-if="item.nivel_13_inbi == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_13_inbi == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_13_inbi == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_13_inbi == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_14_inbi="{ item }">
					<v-chip v-if="item.nivel_14_inbi == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_14_inbi == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_14_inbi == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_14_inbi == 3" small color="green" dark></v-chip>
				</template>

				<!-- wfcwqcwcwcc -->
				<!-- wfcwqcwcwcc -->
				<!-- wfcwqcwcwcc -->

				<template v-slot:item.nivel_1_teens="{ item }" class="cyan lighten-4 pa-1">
					<v-chip v-if="item.nivel_1_teens == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_1_teens == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_1_teens == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_1_teens == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_2_teens="{ item }">
					<v-chip v-if="item.nivel_2_teens == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_2_teens == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_2_teens == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_2_teens == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_3_teens="{ item }">
					<v-chip v-if="item.nivel_3_teens == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_3_teens == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_3_teens == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_3_teens == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_4_teens="{ item }">
					<v-chip v-if="item.nivel_4_teens == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_4_teens == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_4_teens == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_4_teens == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_5_teens="{ item }">
					<v-chip v-if="item.nivel_5_teens == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_5_teens == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_5_teens == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_5_teens == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_6_teens="{ item }">
					<v-chip v-if="item.nivel_6_teens == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_6_teens == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_6_teens == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_6_teens == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_7_teens="{ item }">
					<v-chip v-if="item.nivel_7_teens == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_7_teens == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_7_teens == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_7_teens == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_8_teens="{ item }">
					<v-chip v-if="item.nivel_8_teens == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_8_teens == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_8_teens == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_8_teens == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_9_teens="{ item }">
					<v-chip v-if="item.nivel_9_teens == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_9_teens == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_9_teens == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_9_teens == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_10_teens="{ item }">
					<v-chip v-if="item.nivel_10_teens == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_10_teens == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_10_teens == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_10_teens == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_11_teens="{ item }">
					<v-chip v-if="item.nivel_11_teens == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_11_teens == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_11_teens == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_11_teens == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_12_teens="{ item }">
					<v-chip v-if="item.nivel_12_teens == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_12_teens == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_12_teens == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_12_teens == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_13_teens="{ item }">
					<v-chip v-if="item.nivel_13_teens == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_13_teens == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_13_teens == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_13_teens == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_14_teens="{ item }">
					<v-chip v-if="item.nivel_14_teens == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_14_teens == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_14_teens == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_14_teens == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_15_teens="{ item }">
					<v-chip v-if="item.nivel_15_teens == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_15_teens == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_15_teens == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_15_teens == 3" small color="green" dark></v-chip>
				</template>

				<template v-slot:item.nivel_16_teens="{ item }">
					<v-chip v-if="item.nivel_16_teens == 0" small color="red" dark></v-chip>
					<v-chip v-if="item.nivel_16_teens == 1" small color="purple" dark>P</v-chip>
					<v-chip v-if="item.nivel_16_teens == 2" small color="orange" dark>T</v-chip>
					<v-chip v-if="item.nivel_16_teens == 3" small color="green" dark></v-chip>
				</template>

			</v-data-table>
		</v-col>
		

		<v-dialog
	    v-model="dialog"
	    max-width="800"
	  >
	    <v-card v-if="usuario">
	      <v-card-title class="text-subtitle-1">
	        {{ usuario.nombre_completo }}
	      </v-card-title>

	      <v-card-text class="black--text">
	      	<div>
	      		Planteles del usuario
	      	</div>
	      	<div v-for="(plantel, i ) in usuario.planteles" :key="i">
	      		<v-icon color="primary">mdi-circle-medium</v-icon>{{ plantel.plantel }}
	      	</div>	

	      	<br/>
	      	<br/>

	      	<div>
	      		Horarios
	      	</div>
      		<v-row>
  		    	<v-col cols="12" sm="6" md="5" lg="4" xl="3" v-for="(dia, j) in dias" :key="j">
  		    		<v-card class="shadowCard">
    		    		<v-card-title primary-title class="py-0 text-subtitle-1">
    		    		  {{ dia.dia }}
    		    		  <v-spacer></v-spacer>
    		    		</v-card-title>
								<v-card-text>
									<v-data-table
										v-if="usuario.horarios"
									  dense
								    :headers="headersHorarios"
								    :items="usuario.horarios.filter( el => { return el.dia == dia.valor })"
								    item-key="name"
								    class="elevation-0"
								    hide-default-footer
								    :items-per-page="100"
								    :mobile-breakpoint="200"
									>
								   </v-data-table>	    		    		  
    		    		</v-card-text>
  		    		</v-card>
  		    	</v-col>
  		    </v-row>

	      </v-card-text>
	      
	      <v-card-actions>
	        <v-spacer></v-spacer>
	        <v-btn
	          color="green"
	          rounded
	          small
	          dark
	          @click="dialog = false"
	        >
	          Aceptar
	        </v-btn>
	      </v-card-actions>
	    </v-card>
	  </v-dialog>
	</v-row>

</template>
<script>
import axios from 'axios';


	export default {
		props:[
			'headers',
			'items',
			'search'
		],

		data () {
      return {
        dialog:  false,
        usuario: null,
        dias:[
        	{dia:'Lunes'    , valor: 0 },
        	{dia:'Martes'   , valor: 1 },
        	{dia:'Miércoles', valor: 2 },
        	{dia:'Jueves'   , valor: 3 },
        	{dia:'Viernes'  , valor: 4 },
        	{dia:'Sábado'   , valor: 5 },
        	{dia:'Domingo'  , valor: 6 }
        ],
        headersHorarios: [
	        { text: 'Inicio', value: 'inicio' },
	        { text: 'Fin'   , value: 'fin' },
	      ],

      }
    },

		methods: {
			infoTeacher( usuario ){
				this.usuario  = usuario
				this.dialog   = true
			}
		}
	}
</script>


<style>

	#tabla_teachers td:nth-child(2) {
		background-color: #E8E8E8 !important;
	}

	#tabla_teachers td:nth-child(4) {
		background-color: #E8E8E8 !important;
	}

	#tabla_teachers td:nth-child(6) {
		background-color: #E8E8E8 !important;
	}

	#tabla_teachers td:nth-child(8) {
		background-color: #E8E8E8 !important;
	}

	#tabla_teachers td:nth-child(10) {
		background-color: #E8E8E8 !important;
	}

	#tabla_teachers td:nth-child(12) {
		background-color: #E8E8E8 !important;
	}

	#tabla_teachers td:nth-child(14) {
		background-color: #E8E8E8 !important;
	}

	#tabla_teachers td:nth-child(16) {
		background-color: #E8E8E8 !important;
	}

	#tabla_teachers td:nth-child(18) {
		background-color: #E8E8E8 !important;
	}

	#tabla_teachers td:nth-child(20) {
		background-color: #E8E8E8 !important;
	}

	#tabla_teachers td:nth-child(22) {
		background-color: #E8E8E8 !important;
	}

	#tabla_teachers td:nth-child(24) {
		background-color: #E8E8E8 !important;
	}

	#tabla_teachers td:nth-child(26) {
		background-color: #E8E8E8 !important;
	}

	#tabla_teachers td:nth-child(28) {
		background-color: #E8E8E8 !important;
	}

	#tabla_teachers td:nth-child(30) {
		background-color: #E8E8E8 !important;
	}

	#tabla_teachers td:nth-child(32) {
		background-color: #E8E8E8 !important;
	}

	#tabla_teachers td:nth-child(34) {
		background-color: #E8E8E8 !important;
	}

	#tabla_teachers td:nth-child(36) {
		background-color: #E8E8E8 !important;
	}




</style>