var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"fixed-header":"","search":_vm.search,"id":"tabla_teachers"},scopedSlots:_vm._u([{key:"item.nombre_completo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre_completo)+" ")]}},{key:"item.avance_teoria",fn:function(ref){
var item = ref.item;
return [(item.avance_teoria == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}},[_vm._v(" "+_vm._s(item.avance_teoria))]):_vm._e(),(item.avance_teoria > 0 && item.avance_teoria < 100 )?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v(" "+_vm._s(item.avance_teoria))]):_vm._e(),(item.avance_teoria >= 100 )?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}},[_vm._v(_vm._s(item.avance_teoria))]):_vm._e()]}},{key:"item.avance_practica",fn:function(ref){
var item = ref.item;
return [(item.avance_practica == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}},[_vm._v(" "+_vm._s(item.avance_practica))]):_vm._e(),(item.avance_practica > 0 && item.avance_practica < 100 )?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v(" "+_vm._s(item.avance_practica))]):_vm._e(),(item.avance_practica >= 100 )?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}},[_vm._v(_vm._s(item.avance_practica))]):_vm._e()]}},{key:"item.avance_general",fn:function(ref){
var item = ref.item;
return [(item.avance_general == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}},[_vm._v(" "+_vm._s(item.avance_general))]):_vm._e(),(item.avance_general > 0 && item.avance_general < 100 )?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v(" "+_vm._s(item.avance_general))]):_vm._e(),(item.avance_general >= 100 )?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}},[_vm._v(_vm._s(item.avance_general))]):_vm._e()]}},{key:"item.avance_inbi_t",fn:function(ref){
var item = ref.item;
return [(item.avance_inbi_t == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}},[_vm._v(" "+_vm._s(item.avance_inbi_t))]):_vm._e(),(item.avance_inbi_t > 0 && item.avance_inbi_t < 100 )?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v(" "+_vm._s(item.avance_inbi_t))]):_vm._e(),(item.avance_inbi_t >= 100 )?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}},[_vm._v(_vm._s(item.avance_inbi_t))]):_vm._e()]}},{key:"item.avance_inbi_p",fn:function(ref){
var item = ref.item;
return [(item.avance_inbi_p == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}},[_vm._v(" "+_vm._s(item.avance_inbi_p))]):_vm._e(),(item.avance_inbi_p > 0 && item.avance_inbi_p < 100 )?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v(" "+_vm._s(item.avance_inbi_p))]):_vm._e(),(item.avance_inbi_p >= 100 )?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}},[_vm._v(_vm._s(item.avance_inbi_p))]):_vm._e()]}},{key:"item.avance_inbi_g",fn:function(ref){
var item = ref.item;
return [(item.avance_inbi_g == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}},[_vm._v(" "+_vm._s(item.avance_inbi_g))]):_vm._e(),(item.avance_inbi_g > 0 && item.avance_inbi_g < 100 )?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v(" "+_vm._s(item.avance_inbi_g))]):_vm._e(),(item.avance_inbi_g >= 100 )?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}},[_vm._v(_vm._s(item.avance_inbi_g))]):_vm._e()]}},{key:"item.avance_teens_t",fn:function(ref){
var item = ref.item;
return [(item.avance_teens_t == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}},[_vm._v(" "+_vm._s(item.avance_teens_t))]):_vm._e(),(item.avance_teens_t > 0 && item.avance_teens_t < 100 )?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v(" "+_vm._s(item.avance_teens_t))]):_vm._e(),(item.avance_teens_t >= 100 )?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}},[_vm._v(_vm._s(item.avance_teens_t))]):_vm._e()]}},{key:"item.avance_teens_p",fn:function(ref){
var item = ref.item;
return [(item.avance_teens_p == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}},[_vm._v(" "+_vm._s(item.avance_teens_p))]):_vm._e(),(item.avance_teens_p > 0 && item.avance_teens_p < 100 )?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v(" "+_vm._s(item.avance_teens_p))]):_vm._e(),(item.avance_teens_p >= 100 )?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}},[_vm._v(_vm._s(item.avance_teens_p))]):_vm._e()]}},{key:"item.avance_teens_g",fn:function(ref){
var item = ref.item;
return [(item.avance_teens_g == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}},[_vm._v(" "+_vm._s(item.avance_teens_g))]):_vm._e(),(item.avance_teens_g > 0 && item.avance_teens_g < 100 )?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v(" "+_vm._s(item.avance_teens_g))]):_vm._e(),(item.avance_teens_g >= 100 )?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}},[_vm._v(_vm._s(item.avance_teens_g))]):_vm._e()]}},{key:"item.nivel_1_fast",fn:function(ref){
var item = ref.item;
return [(item.nivel_1_fast == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_1_fast == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_1_fast == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_1_fast == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_2_fast",fn:function(ref){
var item = ref.item;
return [(item.nivel_2_fast == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_2_fast == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_2_fast == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_2_fast == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_3_fast",fn:function(ref){
var item = ref.item;
return [(item.nivel_3_fast == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_3_fast == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_3_fast == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_3_fast == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_4_fast",fn:function(ref){
var item = ref.item;
return [(item.nivel_4_fast == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_4_fast == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_4_fast == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_4_fast == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_5_fast",fn:function(ref){
var item = ref.item;
return [(item.nivel_5_fast == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_5_fast == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_5_fast == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_5_fast == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_6_fast",fn:function(ref){
var item = ref.item;
return [(item.nivel_6_fast == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_6_fast == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_6_fast == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_6_fast == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_7_fast",fn:function(ref){
var item = ref.item;
return [(item.nivel_7_fast == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_7_fast == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_7_fast == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_7_fast == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_8_fast",fn:function(ref){
var item = ref.item;
return [(item.nivel_8_fast == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_8_fast == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_8_fast == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_8_fast == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_9_fast",fn:function(ref){
var item = ref.item;
return [(item.nivel_9_fast == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_9_fast == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_9_fast == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_9_fast == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_10_fast",fn:function(ref){
var item = ref.item;
return [(item.nivel_10_fast == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_10_fast == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_10_fast == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_10_fast == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_11_fast",fn:function(ref){
var item = ref.item;
return [(item.nivel_11_fast == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_11_fast == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_11_fast == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_11_fast == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_12_fast",fn:function(ref){
var item = ref.item;
return [(item.nivel_12_fast == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_12_fast == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_12_fast == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_12_fast == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_13_fast",fn:function(ref){
var item = ref.item;
return [(item.nivel_13_fast == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_13_fast == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_13_fast == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_13_fast == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_14_fast",fn:function(ref){
var item = ref.item;
return [(item.nivel_14_fast == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_14_fast == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_14_fast == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_14_fast == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_1_inbi",fn:function(ref){
var item = ref.item;
return [(item.nivel_1_inbi == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_1_inbi == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_1_inbi == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_1_inbi == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_2_inbi",fn:function(ref){
var item = ref.item;
return [(item.nivel_2_inbi == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_2_inbi == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_2_inbi == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_2_inbi == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_3_inbi",fn:function(ref){
var item = ref.item;
return [(item.nivel_3_inbi == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_3_inbi == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_3_inbi == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_3_inbi == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_4_inbi",fn:function(ref){
var item = ref.item;
return [(item.nivel_4_inbi == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_4_inbi == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_4_inbi == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_4_inbi == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_5_inbi",fn:function(ref){
var item = ref.item;
return [(item.nivel_5_inbi == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_5_inbi == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_5_inbi == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_5_inbi == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_6_inbi",fn:function(ref){
var item = ref.item;
return [(item.nivel_6_inbi == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_6_inbi == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_6_inbi == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_6_inbi == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_7_inbi",fn:function(ref){
var item = ref.item;
return [(item.nivel_7_inbi == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_7_inbi == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_7_inbi == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_7_inbi == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_8_inbi",fn:function(ref){
var item = ref.item;
return [(item.nivel_8_inbi == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_8_inbi == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_8_inbi == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_8_inbi == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_9_inbi",fn:function(ref){
var item = ref.item;
return [(item.nivel_9_inbi == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_9_inbi == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_9_inbi == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_9_inbi == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_10_inbi",fn:function(ref){
var item = ref.item;
return [(item.nivel_10_inbi == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_10_inbi == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_10_inbi == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_10_inbi == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_11_inbi",fn:function(ref){
var item = ref.item;
return [(item.nivel_11_inbi == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_11_inbi == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_11_inbi == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_11_inbi == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_12_inbi",fn:function(ref){
var item = ref.item;
return [(item.nivel_12_inbi == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_12_inbi == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_12_inbi == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_12_inbi == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_13_inbi",fn:function(ref){
var item = ref.item;
return [(item.nivel_13_inbi == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_13_inbi == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_13_inbi == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_13_inbi == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_14_inbi",fn:function(ref){
var item = ref.item;
return [(item.nivel_14_inbi == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_14_inbi == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_14_inbi == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_14_inbi == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_1_teens",fn:function(ref){
var item = ref.item;
return [(item.nivel_1_teens == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_1_teens == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_1_teens == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_1_teens == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_2_teens",fn:function(ref){
var item = ref.item;
return [(item.nivel_2_teens == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_2_teens == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_2_teens == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_2_teens == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_3_teens",fn:function(ref){
var item = ref.item;
return [(item.nivel_3_teens == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_3_teens == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_3_teens == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_3_teens == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_4_teens",fn:function(ref){
var item = ref.item;
return [(item.nivel_4_teens == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_4_teens == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_4_teens == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_4_teens == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_5_teens",fn:function(ref){
var item = ref.item;
return [(item.nivel_5_teens == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_5_teens == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_5_teens == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_5_teens == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_6_teens",fn:function(ref){
var item = ref.item;
return [(item.nivel_6_teens == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_6_teens == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_6_teens == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_6_teens == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_7_teens",fn:function(ref){
var item = ref.item;
return [(item.nivel_7_teens == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_7_teens == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_7_teens == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_7_teens == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_8_teens",fn:function(ref){
var item = ref.item;
return [(item.nivel_8_teens == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_8_teens == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_8_teens == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_8_teens == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_9_teens",fn:function(ref){
var item = ref.item;
return [(item.nivel_9_teens == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_9_teens == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_9_teens == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_9_teens == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_10_teens",fn:function(ref){
var item = ref.item;
return [(item.nivel_10_teens == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_10_teens == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_10_teens == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_10_teens == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_11_teens",fn:function(ref){
var item = ref.item;
return [(item.nivel_11_teens == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_11_teens == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_11_teens == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_11_teens == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_12_teens",fn:function(ref){
var item = ref.item;
return [(item.nivel_12_teens == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_12_teens == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_12_teens == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_12_teens == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_13_teens",fn:function(ref){
var item = ref.item;
return [(item.nivel_13_teens == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_13_teens == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_13_teens == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_13_teens == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_14_teens",fn:function(ref){
var item = ref.item;
return [(item.nivel_14_teens == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_14_teens == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_14_teens == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_14_teens == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_15_teens",fn:function(ref){
var item = ref.item;
return [(item.nivel_15_teens == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_15_teens == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_15_teens == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_15_teens == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}},{key:"item.nivel_16_teens",fn:function(ref){
var item = ref.item;
return [(item.nivel_16_teens == 0)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}}):_vm._e(),(item.nivel_16_teens == 1)?_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v("P")]):_vm._e(),(item.nivel_16_teens == 2)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("T")]):_vm._e(),(item.nivel_16_teens == 3)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}}):_vm._e()]}}])})],1),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.usuario)?_c('v-card',[_c('v-card-title',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.usuario.nombre_completo)+" ")]),_c('v-card-text',{staticClass:"black--text"},[_c('div',[_vm._v(" Planteles del usuario ")]),_vm._l((_vm.usuario.planteles),function(plantel,i){return _c('div',{key:i},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-circle-medium")]),_vm._v(_vm._s(plantel.plantel)+" ")],1)}),_c('br'),_c('br'),_c('div',[_vm._v(" Horarios ")]),_c('v-row',_vm._l((_vm.dias),function(dia,j){return _c('v-col',{key:j,attrs:{"cols":"12","sm":"6","md":"5","lg":"4","xl":"3"}},[_c('v-card',{staticClass:"shadowCard"},[_c('v-card-title',{staticClass:"py-0 text-subtitle-1",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(dia.dia)+" "),_c('v-spacer')],1),_c('v-card-text',[(_vm.usuario.horarios)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.headersHorarios,"items":_vm.usuario.horarios.filter( function (el) { return el.dia == dia.valor }),"item-key":"name","hide-default-footer":"","items-per-page":100,"mobile-breakpoint":200}}):_vm._e()],1)],1)],1)}),1)],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green","rounded":"","small":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Aceptar ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }